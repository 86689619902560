import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

// Create the context
interface SocketContextType {
    socket: Socket | null;
    code: string;
    setCode: (str: string) => void;
    connect: (code: string) => Promise<void>;
  }
const SocketContext = createContext<SocketContextType | undefined>(undefined);

// Create the provider component
interface SocketProviderProps {
  children: ReactNode;
  socketUrl: string; // The Socket.io server URL
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children, socketUrl }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [code, setCode] = useState<string>("");

  useEffect(() => {
   
   
    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  const connect = (code: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      console.log("Context connect", code)
      const socketInstance = io(socketUrl, {auth: {token: code, apiKey: "Kq#L4t<M8VoE25r|-h" }});
     
      socketInstance?.on("connect", () => {
        setSocket(socketInstance);
        console.log(socketInstance.id)
        resolve()
      });

      socketInstance?.on("connect_error", (err) => {
       reject(err)
      });
      
     
     
    })
    
  }

  return (
    <SocketContext.Provider value={{ socket, code, setCode, connect }}>
      {children}
    </SocketContext.Provider>
  );
}

// Custom hook for components to access the socket
export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};
