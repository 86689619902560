
import * as React from 'react';
import { useState } from "react"
import { View, Text } from "react-native"
import { TextInput, Switch } from 'react-native-paper' 
import { Spacer } from '../layout';

export const KeywordOrRow = ({params, toolParams, setToolParams}) => {
    const [text, setText] = useState(toolParams[params.key] ? toolParams[params.key] : "")
    const [offset, setOffset] = useState(toolParams[params.key + "offset"] ? toolParams[params.key + "offset"] : "")
    const onToggleSwitch = () => {
      setToolParams(Object.assign({}, toolParams, {[params.key + "toggle"]: !toolParams[params.key + "toggle"]}))
    }

    return <View style={{borderWidth: 1, padding: 10, borderRadius: 5}}>
      <Text>{params.label}</Text>
      <View style={{flexDirection: 'row',  alignItems: 'center', padding: 5}}>
      <View style={{flexDirection: 'row', alignItems: 'center', paddingRight: 15}}>
        <Text>Keyword</Text>
        <Spacer width={5} height={undefined}/>
        <Switch value={toolParams[params.key + "toggle"] ? toolParams[params.key + "toggle"] : false} onValueChange={onToggleSwitch} />
        <Spacer width={5}/>
        <Text>Row Number</Text>
      </View>

      <View style={{flex: 1}}>
      <TextInput label={`${params.label} ${toolParams[params.key + "toggle"] ? "Index" : "Keyword"}`} value={text} onChangeText={(txt) => {
      return setText(txt)
    }} onBlur={(txt) => {setToolParams(Object.assign({}, toolParams, {[params.key]: text}))}}/>

<TextInput label={"Offset"} value={offset} onChangeText={(txt) => {
      return setOffset(txt)
    }} onBlur={(txt) => {setToolParams(Object.assign({}, toolParams, {[params.key + "offset"]: offset}))}}/>
    </View>
    
    </View>
    </View>
  }