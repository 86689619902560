import { Button, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import * as React from 'react';
import { AntDesign } from '@expo/vector-icons'
import { useState } from 'react';

export const Dropdown = ({children, open, opened}) => {
    return <View>
        <TouchableOpacity onPress={() => {
            open(!opened)
        }}>
        <AntDesign name="plussquareo" size={25} color="blue" />
      </TouchableOpacity>

      {
        opened ? children : <></>
    }
    </View>
}