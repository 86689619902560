import * as React from 'react';
import { View, Text, TouchableOpacity, ScrollView, StyleSheet } from 'react-native';
import { Spacer } from '../layout';
import { Feather} from '@expo/vector-icons'

const FilterItem = ({id, filter, removeLastFilter}) => {
    console.log(filter)
    return <>
      <View style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 7, alignItems: 'center'}}>
        <Text style={{flex: 1}}>{id + 1}</Text>
        <Text style={{flex: 5}}>{filter.id}</Text>
        {
          removeLastFilter ? <TouchableOpacity style={{position: 'absolute', right: 5, justifyContent: 'center', alignItems: 'center'}} onPress={removeLastFilter}>
            <Feather name='x' size={18} color={'red'} />
          </TouchableOpacity> : <></>
        }
        </View>
        <Spacer height={5}/>
    </>
  }



  export const FilterBar = ({data, selectedPage, removeLastFilter}) => {
    return <View style={[{flex: 1, backgroundColor: 'white', padding: 10, borderRadius: 10}, styles.shadow]}>
    <Text style={{fontSize: 20}}>Filters</Text>
    <View style={{width: '100%', borderBottomWidth: 1, padding: 2, borderColor: 'grey',}}/>
    <ScrollView>
    <Spacer height={10}/>

    {
      data.find(pagedat => pagedat.name == selectedPage)?.filters ? data.find(pagedat => pagedat.name == selectedPage)?.filters.map((filter, i) => {
        return <FilterItem id={i} filter={filter} key={filter?.id ? filter.id + i: 'filter' + i} removeLastFilter={i == data.find(pagedat => pagedat.name == selectedPage)?.filters.length - 1 ? removeLastFilter : undefined}/>
      }) : <></>
    }
    </ScrollView>
    </View>
  }

  const styles = StyleSheet.create({
    shadow: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.3,
        shadowRadius: 5
      }
  })