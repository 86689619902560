import { View, TouchableOpacity, Text, StyleSheet } from "react-native"
  
  export const Row = (props) => {
    return <TouchableOpacity onPress={props.onPress} style={{flexDirection: 'row', borderBottomWidth: 1, borderColor: 'grey'}}>
      {props.children}
    </TouchableOpacity>
  }
  
  export const Cell = ({col, val, flex, disabled}) => {
    return <TouchableOpacity style={{flex: flex, padding: 3}} disabled={disabled}>
      <Text>{val}</Text>
    </TouchableOpacity>
  }

  export const Table = (props) => {
    return <View style={styles.container}>
    {props.children}
    </View>
  }

  export const TableHeader = ({items, itemOnPress}) => {
    return <View style={{flexDirection: 'row', borderBottomWidth: 1}}>
      {
        items.map((item, index) => {
          return <TouchableOpacity key={index} onPress={() => itemOnPress(item)} style={{flex: item.flex, alignItems: 'flex-start', padding: 5}}>
            <Text>{item.label}</Text>
          </TouchableOpacity>
        })
      }
    </View>
  }

  const styles = StyleSheet.create({
    container: { flex: 1, padding: 16, backgroundColor: '#fff', borderRadius: 10},
    head: { height: 40, backgroundColor: '#f1f8ff' },
    text: { margin: 6 },
    selectedTab: {
      paddingHorizontal: 15, 
      paddingVertical: 2, 
      backgroundColor: 'blue', 
      alignSelf: 'flex-start', 
      borderTopRightRadius: 10, 
      borderTopLeftRadius: 10
      },
      deselectedTab: {
        paddingHorizontal: 15, 
        paddingVertical: 2, 
        backgroundColor: 'grey', 
        alignSelf: 'flex-start', 
        borderTopRightRadius: 10, 
        borderTopLeftRadius: 10
      },
      shadow: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.3,
        shadowRadius: 5
      }
  });