import { Device, Section } from "RecipeManager/build/types/main/main";
import * as React from "react"
import { View, Text } from "react-native"
import { TableScreen } from "../components/EditorDisplays/TableScreen";
import { useSocket } from "../contexts/SocketContext";
import { DataFrame } from "RecipeManager/build/types/frames/anyFrame";
import { LinearGradient } from "expo-linear-gradient";


interface FinalizeScreenProps {
    route: {
        params: {
            data: Device;  // Define a specific type for 'data' if known
        }
    };
    navigation: any;  // Define a specific type for 'navigation' if known
}

const getRandomColor = (): string => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  
export const FinalizeScreen: React.FC<FinalizeScreenProps> = ({route }) => {
    const sock = useSocket()
    const {data} = route.params

    const [thisDevice, setThisDevice] = React.useState<Device>(data)
    const [thisSection, setSection] = React.useState<{id: string, method: "CLI" | "SNMP" | "COMBO", name: string, pages: {id: string, name: string, output: DataFrame}[]}[]>(data.sections.map((s: Section) => {
        return {
            id: s.id,
            method: s.method.type,
            name: s.name,
            pages: s?.pages?.map(p => {
                return {
                    id: p.id,
                    name: p.name,
                    output: p.outputDataFrame
                }
            })
        }
    }))


    React.useEffect(() => {
        sock?.socket?.on("sections", (sections) => {
            setSection(sections.map((s: Section) => {
                return {
                    id: s.id,
                    method: s.method.type,
                    name: s.name,
                    pages: s?.pages?.map(p => {
                        return {
                            id: p.id,
                            name: p.name,
                            output: p.outputDataFrame
                        }
                    })
                }
            }))
        })

        sock?.socket?.emit("getSections", data.id)

        return () => {sock.socket?.removeListener("section")}
    }, [])

    return <View style={{padding: 10}}>
        <Text style={{fontSize: 25}}>Finalize Recipe</Text>
        {
            thisSection.map(obj => {
                return  <LinearGradient
                colors={['white', getRandomColor()]}
                style={{ padding: 5, margin: 10, borderWidth: 1, borderRadius: 5}}
              >
                    <Text style={{fontSize: 16}}>{obj.name} [{obj.method}]</Text>
                    {
                        obj?.pages?.map(p => {
                            return <View style={{padding: 5, margin: 5, borderWidth: 1, borderRadius: 5, backgroundColor: 'white'}}>
                                <Text style={{fontSize: 16}}>{p.name}</Text>
                                {
                                    p.output.type == "string" ? <Text style={{padding: 10}}>{p.output.data}</Text> : <TableScreen output={p.output}/>
                                }
                              
                            </View>
                        })
                    }
                </LinearGradient>
            })
        }

    </View>
}