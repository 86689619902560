// RootNavigation.ts (renamed from RootNavigation.js to better reflect TypeScript conventions)

import { createNavigationContainerRef } from '@react-navigation/native';
import { NavigationContainerRef } from '@react-navigation/core';

export const navigationRef: NavigationContainerRef<any> = createNavigationContainerRef();

export function navigate(name: string, params?: object) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}

export function goBack() {
  if (navigationRef.isReady()) {
    navigationRef.goBack();
  }
}

export function setRoot(name: string, params?: object) {
  if (navigationRef.isReady()) {
    navigationRef.reset({
      index: 0,
      routes: [{ name, params }],
    });
  }
}

export function getCurrentRoute(): string | undefined {
  if (navigationRef.isReady()) {
    const state = navigationRef.getRootState();
    let route;
    route = state.routes[state.index];
    
    // Dive into nested navigators
    while (route.state && route.state.index !== undefined) {
      route = route.state.routes[route.state.index];
    }
    return route.name;
  }
}

// Add other navigation functions as needed and export them
