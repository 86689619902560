import { Button, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import * as React from "react"
import { AntDesign } from '@expo/vector-icons'
import { useState } from 'react';
import { Spacer } from './layout';

export const DropdownList = ({items, selected, select}) => {
    const [opened, open] = useState(false)
    return <View style={{minWidth: 100}}>
        <TouchableOpacity style={{minWidth: 200, alignItems: 'center', flexDirection: 'row', paddingVertical: 3, paddingHorizontal: 10, borderWidth: 1, alignSelf: 'flex-start'}} onPress={() => {
            open(!opened)
        }}>
       <Text>{selected}</Text>
       <Spacer width={10}/>
       <AntDesign name={opened ? "caretup" : "caretdown"} size={10} color="black" style={{position: 'absolute', right: 5}}/>
      </TouchableOpacity>

      {
        opened ?
      <View style={{borderWidth: 1, position: 'absolute', zIndex: 2, backgroundColor: 'white', top: 25, left: 0, right: 0, padding: 10, borderRadius: 5, maxWidth: 500}}>
        {
          items.map((item) => {
            return <TouchableOpacity style={{padding: 2, borderWidth: selected == item ? 1: 0,  borderBottomColor: selected == item ? 'blue': 'white'}}key={item} onPress={() => {
              select(item)
              open(false)
            }}>
              <Text style={{flex: 1}}>{item}</Text>
            </TouchableOpacity>
          })
        }
      </View> : <></>
    }
    </View>
}