import * as React from "react"
import { View, Text, TouchableOpacity, Button } from "react-native"
import { Spacer } from "../components/layout"
import { Switch, TextInput } from "react-native-paper"
import { useState } from "react"
import { useSocket } from "../contexts/SocketContext"
import { Page, Section } from "RecipeManager/build/types/main/main"

interface ColItemProps {
    col: any;  // Define a specific type for 'col' if known
    selectable: string;
    sectionID: string;
    pageID: string;
    primaryItem: any;  // Define a specific type for 'primaryItem' if known
    secondaryItem: any;  // Define a specific type for 'secondaryItem' if known
    selectItem: string;
    setSelectable: (value: string) => void;
    setPrimary: (value: any) => void;  // Define a specific function type for 'setPrimary'
    setSecondary: (value: any) => void;  // Define a specific function type for 'setSecondary'
    sectionName: string;
    pageName: string;
}
const ColItem: React.FC<ColItemProps> = ({ col, pageID, sectionID, selectable, primaryItem, secondaryItem, selectItem, setSelectable, setPrimary, setSecondary, sectionName, pageName }) => {
    var backgroundColor = "white"
    if (selectable == "col") backgroundColor = 'deepskyblue'
    if (primaryItem?.col == col.index && primaryItem?.pageName == pageName) backgroundColor = "green"
    if (secondaryItem?.col == col.index && secondaryItem?.pageName == pageName) backgroundColor = "pink"

    return <>
        <TouchableOpacity disabled={selectable !== "col"} style={{ borderWidth: 1, borderRadius: 5, padding: 2, backgroundColor: backgroundColor }} onPress={() => {
            selectItem == "primary" ? setPrimary({
                section: sectionName,
                pageName: pageName,
                col: col.index,
                sectionID,
                pageID
            }) : setSecondary({
                section: sectionName,
                pageName: pageName,
                col: col.index,
                sectionID,
                pageID
            })
            setSelectable("")
        }}>

            <Text>{col.name}</Text>
            <Text style={{ fontSize: 12 }}>{col.index} {col.type}</Text>
        </TouchableOpacity>
        <Spacer width={5} />
    </>
}

interface PageRowProps {
    sectionName: string;
    setSelectable: (value: string) => void;
    sectionID: string;
    page: Page;  // Define a specific type for 'page' if known
    selectable: string;
    selectItem: string;
    primaryItem: any;  // Define a specific type for 'primaryItem' if known
    secondaryItem: any;  // Define a specific type for 'secondaryItem' if known
    setPrimary: (value: any) => void;  // Define a specific function type for 'setPrimary'
    setSecondary: (value: any) => void;  // Define a specific function type for 'setSecondary'
}
const PageRow: React.FC<PageRowProps> = ({ sectionName, sectionID, setSelectable, page, selectable, selectItem, primaryItem, secondaryItem, setPrimary, setSecondary }) => {
    var backgroundColor = "white"
    if (selectable == "page" && page.outputDataFrame.type == "string") backgroundColor = 'deepskyblue'
    if (primaryItem?.pageName == page.name && !Object.keys(primaryItem).includes("col")) backgroundColor = "green"
    if (secondaryItem?.pageName == page.name && !Object.keys(secondaryItem).includes("col")) backgroundColor = "pink"

    return <View style={{ flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1 }}>
        <TouchableOpacity disabled={selectable !== "page" || page.outputDataFrame.type !== "string"} style={{ flex: 1, height: '100%', padding: 5, backgroundColor: backgroundColor, borderRadius: 5 }} onPress={() => {
            selectItem == "primary" ? setPrimary({
                section: sectionName,
                pageName: page.name,
                pageID: page.id,
                sectionID: sectionID
            }) : setSecondary({
                section: sectionName,
                pageName: page.name,
                pageID: page.id,
                sectionID: sectionID
            })
            setSelectable("")
        }}>
            <Text>{page.name}</Text>
            <Text>{page.outputDataFrame.type}</Text>
        </TouchableOpacity>

        <View style={{ flex: 10, flexDirection: 'row', alignItems: 'center', padding: 5, flexWrap: 'wrap', gap: 3, borderLeftWidth: 1 }}>
            {
                page.outputDataFrame.header ? page.outputDataFrame.header.map((col: any) => {
                    return <ColItem key={col.index} primaryItem={primaryItem} secondaryItem={secondaryItem} selectItem={selectItem} setSelectable={setSelectable} setPrimary={setPrimary} setSecondary={setSecondary} sectionID={sectionID} sectionName={sectionName} pageID={page.id} pageName={page.name} selectable={selectable} col={col} />
                }) : <></>
            }
        </View>
    </View>
}

interface SectionRowProps {
    section: Section;  // Define a specific type for 'section' if known
    selectable: string;
    setSelectable: (value: string) => void;
    selectItem: string;
    primaryItem: any;  // Define a specific type for 'primaryItem' if known
    secondaryItem: any;  // Define a specific type for 'secondaryItem' if known
    setPrimary: (value: any) => void;  // Define a specific function type for 'setPrimary'
    setSecondary: (value: any) => void;  // Define a specific function type for 'setSecondary'
}

const SectionRow: React.FC<SectionRowProps> = ({ section, selectable, setSelectable, selectItem, primaryItem, secondaryItem, setPrimary, setSecondary }) => {
    return <View style={{ flexDirection: 'row', alignItems: 'center', padding: 10, borderWidth: 1, backgroundColor: 'white', borderRadius: 5, marginBottom: 10 }}>
        <View style={{ flex: 1, borderRightWidth: 1, height: '100%', padding: 5 }}>
            <Text>{section.name}</Text>
            <Text>{section.method.type}</Text>
        </View>

        <View style={{ flex: 10 }}>
            {
                section?.pages?.map((page: any) => {
                    return <PageRow setSelectable={setSelectable} sectionID={section.id} sectionName={section.name} page={page} selectable={selectable} key={page.name} selectItem={selectItem} primaryItem={primaryItem} secondaryItem={secondaryItem} setPrimary={setPrimary} setSecondary={setSecondary} />
                })
            }
        </View>


    </View>
}







interface CombineSectionScreenProps {
    route: {
        params: {
            data: any[];  // Define a specific type for 'data' if known
            device: any;  // Define a specific type for 'device' if known
            socket: any;  // Define a specific type for 'socket' if known
        }
    };
    navigation: any;  // Define a specific type for 'navigation' if known
}

export const CombineSectionScreen: React.FC<CombineSectionScreenProps> = ({ route, navigation }) => {

    const socketContext = useSocket()
  const socket = socketContext.socket
  if (!socket) return <><Text>Socket Connection Error</Text></>


    const { data, device } = route.params

    const [thisSections, setThisSections] = useState<Section[]>(data)
    const [combineString, setCombineString] = useState(false)
    const [selectable, setSelectable] = useState("")
    const [selectItem, setSelectItem] = useState("")
    const [primaryItem, selectPrimaryItem] = useState()
    const [secondaryItem, selectSecondaryItem] = useState()

    const [newSectionName, setNewSectionName] = useState("")
    const [newPageName, setNewPageName] = useState("")
    const [delimiter, setDelimiter] = useState("")


    React.useEffect(() => {
        socket?.on("sections", (sections) => {
            setThisSections(sections)
        })

        socket?.emit("getSections", device.id)

        return () => {socket?.removeListener("section")}
    }, [])

    return <View style={{ padding: 15 }}>
    <View style={{ padding: 5, backgroundColor: 'white', borderRadius: 5, width: '50%' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontSize: 16 }}>Combine</Text>
            <Spacer width={10} />
            <Text style={{ fontSize: 16 }}>Table</Text>
            <Spacer width={5} />
            <Switch value={combineString} onValueChange={
                (val) => {
                    setCombineString(!combineString)
                    setSelectable("")
                    selectPrimaryItem(undefined)
                    selectSecondaryItem(undefined)
                    return
                }
            }
            />
            <Spacer width={5} />
            <Text style={{ fontSize: 16 }}>Text</Text>
        </View>
        <Spacer height={10} />
        {
            combineString ? <>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => {
                        setSelectable("page")
                        setSelectItem("primary")
                    }} style={{ backgroundColor: selectable == "page" && selectItem == "primary" ? "green" : "white", borderWidth: 3, borderRadius: 5, borderColor: 'green', padding: 5 }}><Text>Select Primary Page</Text></TouchableOpacity>
                    <Spacer width={15} />
                    <Text>{// @ts-ignore
                        primaryItem?.section} {primaryItem?.pageName} {primaryItem?.col}</Text>
                </View>

                <Spacer height={5} />
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => {
                        setSelectable("page")
                        setSelectItem("secondary")
                    }} style={{ backgroundColor: selectable == "page" && selectItem == "secondary" ? "pink" : "white", borderWidth: 3, borderRadius: 5, borderColor: 'pink', padding: 5 }}><Text>Select Secondary Page</Text></TouchableOpacity>
                    <Spacer width={15} />
                    <Text>{// @ts-ignore
                        secondaryItem?.section} {secondaryItem?.pageName} {secondaryItem?.col}</Text>
                </View>

                <Spacer height={5} />
                <TextInput label={"Delimiter"} value={delimiter} onChangeText={setDelimiter}/>

                <Spacer height={5} />
                <TextInput label={"Output Section Name"} value={newSectionName} onChangeText={setNewSectionName}/>

                <Spacer height={5} />
                <TextInput label={"Output Page Name"} value={newPageName} onChangeText={setNewPageName}/>
            </> : <>
            <Spacer height={5} />
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    <TouchableOpacity onPress={() => {
                        setSelectable("col")
                        setSelectItem("primary")
                    }} style={{ backgroundColor: selectable == "col" && selectItem == "primary" ? "green" : "white", borderWidth: 3, borderRadius: 5, borderColor: 'green', padding: 5 }}><Text>Select Secondary Column</Text></TouchableOpacity>
                    <Spacer width={15} />
                    <Text style={{fontSize: 18}}><Text style={{fontWeight: 'bold'}}>Section:</Text> {// @ts-ignore
                        primaryItem?.section}{"\n"}<Text style={{fontWeight: 'bold'}}>Page:</Text> {primaryItem?.pageName}{"\n"}<Text style={{fontWeight: 'bold'}}>Column:</Text>{primaryItem?.col}</Text>
                </View>

                <Spacer height={5} />
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    <TouchableOpacity onPress={() => {
                        setSelectable("col")
                        setSelectItem("secondary")
                    }} style={{ backgroundColor: selectable == "col" && selectItem == "secondary" ? "pink" : "white", borderWidth: 3, borderRadius: 5, borderColor: 'pink', padding: 5 }}><Text>Select Secondary Column</Text></TouchableOpacity>
                    <Spacer width={15} />
                    <Text style={{fontSize: 18}}><Text style={{fontWeight: 'bold'}}>Section:</Text> {// @ts-ignore
                        secondaryItem?.section}{"\n"}<Text style={{fontWeight: 'bold'}}>Page:</Text> {secondaryItem?.pageName}{"\n"}<Text style={{fontWeight: 'bold'}}>Column:</Text>{secondaryItem?.col}</Text>
                </View>

                <Spacer height={5} />
                <TextInput label={"Output Section Name"} value={newSectionName} onChangeText={setNewSectionName}/>

                <Spacer height={5} />
                <TextInput label={"Output Page Name"} value={newPageName} onChangeText={setNewPageName}/>
            </>
        }
        <Spacer height={5} />
        <Button title="Combine" onPress={() => {
            socket.emit("combineSections", {
                primary: primaryItem,
                secondary: secondaryItem,
                outSectionName: newSectionName,
                outPageName: newPageName,
                delim: delimiter,
                pageType: combineString ? "string" : "table",
                device: device.name,
                did: device.id
            })
        }} />
    </View>

    <View style={{ flexDirection: 'row', padding: 10 }}>
        <Text style={{ flex: 1.05, fontSize: 18 }}>Section</Text>
        <Text style={{ flex: 0.9, fontSize: 18 }}>Pages</Text>
        <Text style={{ flex: 8, fontSize: 18 }}>Headers</Text>
    </View>
    {
        thisSections.map(section => {
            return <SectionRow section={section} selectable={selectable} setSelectable={setSelectable} selectItem={selectItem} primaryItem={primaryItem} secondaryItem={secondaryItem} setPrimary={selectPrimaryItem} setSecondary={selectSecondaryItem} key={section.name + "-Row"} />
        })
    }
</View>

   /* return */
}