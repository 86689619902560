import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Spacer, Table, Row, Cell } from "../components/layout";
import { TableHeader } from "../components/layout/Table";
import { Dropdown } from "../components/Dropdown";
import { RadioButton, TextInput } from "react-native-paper";
import { Socket } from "socket.io-client";
import { Entypo } from '@expo/vector-icons';

interface SectionScreenProps {
  route: {
    params: {
      device: any; // Define the type for 'device' if available
      socket: Socket;
    };
  };
  navigation: {
    setOptions: (options: any) => void;
    navigate: (routeName: string, params?: any) => void;
    addListener: (event: string, callback: (e: any) => void) => { remove: () => void };
  };
}

export function SectionScreen({ route, navigation }: SectionScreenProps) {
  const { device, socket } = route.params;

  const [deviceDropdownOpen, openDeviceDropdown] = useState<boolean>(false);
  const [newSectionName, setNewSectionName] = useState<string>("");
  const [newSectionType, setNewSectionType] = useState<'CLI' | 'SNMP'>('CLI');
  const [newSectionOpts, setNewSectionOpts] = useState<any>({}); // Define a specific type if known
  const [tableHead, setTableHead] = useState<Array<{ label: string; flex: number; col: string }>>([{label: "Name", flex: 2, col: "c1"}, {label: "Type", flex: 1, col: "c2"}]);
  const [tableData, setTableData] = useState<any[]>([]); // Define a specific type if known

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `${device.name} Sections`,
      headerRight: () => (
        <TouchableOpacity style={{borderWidth: 1, borderRadius: 3, borderColor: 'red', paddingVertical: 3, paddingHorizontal: 15, marginRight: 15 }} onPress={() => {
          socket.emit("deleteDevice", device.id, () => {
            socket.emit("devices");
            return navigation.navigate("Home", {socket: socket});
          });
        }}>
          <Text style={{color: 'red'}}>Delete Device</Text>
        </TouchableOpacity>
      )
    });

    socket.on("sections", (sections) => {
      console.log("Got Sections: ", sections)
      setTableData(sections);
    });

    return () => {
      socket.off("sections");
    };
  }, []);

  useEffect(() => {
    const unsub = navigation.addListener('focus', (e) => {
      socket.emit("getSections", device.id);
    });
    return () =>{
     if (unsub) {
      console.log(unsub)
     // unsub.remove()
     }
    }
  }, [navigation]);

  const addSection = () => {
    socket.emit("newSection", {did: device.id, name: newSectionName, device: device, type: newSectionType, opts: newSectionOpts});
    openDeviceDropdown(false);
  }

  return (
    <View style={{padding: 20}}>
      <Spacer height={40} />

      <Table>
        <TableHeader items={tableHead} itemOnPress={(x: any) => console.log(x)}/>
        {
          tableData?.map(section => {
            console.log(section)
            return (
              <Row key={section.id} onPress={() => {
                socket.emit("compute filters", {device: device.name, section: section.name}); 
                navigation.navigate("Editor", {device: device, section: section, socket: socket});
              }}>
                <Cell col={"c1"} val={section.name} flex={tableHead[0].flex} disabled={true}/>
                <Cell col={"c2"} val={section.type} flex={tableHead[1].flex} disabled={true}/>
              </Row>
            );
          })
        }
      </Table>

      <Spacer height={40} />
      <TouchableOpacity onPress={() => {
        navigation.navigate("Combine Sections", {device: device, data: device.sections, socket: socket});
      }} style={{flexDirection: 'row', backgroundColor: 'white', borderRadius: 5, padding: 10, alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 18}}>Combine Sections</Text>
        <Entypo name="chevron-right" size={20} color={"black"} />
      </TouchableOpacity>

      <Spacer height={20} />
      <TouchableOpacity onPress={() => {
        navigation.navigate("Finalize Recipe", {socket: socket, data: device});
      }} style={{flexDirection: 'row', backgroundColor: 'white', borderRadius: 5, padding: 10, alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 18}}>Finalize Recipe</Text>
        <Entypo name="chevron-right" size={20} color={"black"} />
      </TouchableOpacity>

      <View style={{flexDirection: 'row', alignItems: 'center', position: 'absolute'}}>
        <Text style={{fontSize: 25}}>Sections</Text>
        <Spacer width={10} />
        <Dropdown opened={deviceDropdownOpen} open={openDeviceDropdown}>
        <View style={{borderWidth: 1, position: 'absolute', zIndex: 2, backgroundColor: 'white', top: 25, left: 0, padding: 10, borderRadius: 5, maxWidth: 500}}>
        <Text style={{flex: 1}}>Add Section</Text>
        
        <TextInput mode="outlined" label="name" onChangeText={setNewSectionName} value={newSectionName}/>
        <RadioButton.Group onValueChange={value => setNewSectionType(value as "CLI" | "SNMP")} value={newSectionType}>
      <RadioButton.Item label="CLI" value="CLI"/>
      <RadioButton.Item disabled={true} label="SNMP" value="SNMP" />
    </RadioButton.Group>

    {
      newSectionType == "CLI" ? <>
      <TextInput mode="outlined" label={"Command"} onChangeText={(text) => setNewSectionOpts(Object.assign({}, newSectionOpts, {cmd: text}))} value={newSectionOpts?.cmd ? newSectionOpts?.cmd : ""}/>
      <TextInput mode="outlined" label={"Output"} multiline={true} onChangeText={(rawdata) => setNewSectionOpts(Object.assign({}, newSectionOpts, {rawdata: rawdata}))} value={newSectionOpts?.rawdata ? newSectionOpts?.rawdata : ""}/>
      </> : <></>
    }

        <View style={{flexDirection: 'row', flex: 1, paddingVertical: 10}}>
            <TouchableOpacity onPress={() => {
              addSection()
            }} style={{flex: 1, alignItems: 'center'}}>
                <Text>Add Section</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{flex: 1, alignItems: 'center'}} onPress={() => openDeviceDropdown(false)}>
                <Text>Cancel</Text>
            </TouchableOpacity>
        </View>
      </View>
        </Dropdown>
      </View>
    </View>
  );
}
