
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Button, } from "react-native";

export const PageTab = ({label, selectPage, selectedPage}) => {
    if (selectedPage == label) {
      return <TouchableOpacity style={styles.selectedTab} onPress={() => {selectPage(label)}}>
      <Text style={{color: 'white', fontSize: 16 }}>{label}</Text>
    </TouchableOpacity>
    } else return <TouchableOpacity style={styles.deselectedTab} onPress={() => {selectPage(label)}}>
    <Text style={{color: 'white',fontSize: 16 }}>{label}</Text>
  </TouchableOpacity>
  }


const styles = StyleSheet.create({
    container: { flex: 1, padding: 16, backgroundColor: '#fff', borderRadius: 10},
    head: { height: 40, backgroundColor: '#f1f8ff' },
    text: { margin: 6 },
    selectedTab: {
      paddingHorizontal: 15, 
      paddingVertical: 2, 
      backgroundColor: 'blue', 
      alignSelf: 'flex-start', 
      borderTopRightRadius: 10, 
      borderTopLeftRadius: 10
      },
      deselectedTab: {
        paddingHorizontal: 15, 
        paddingVertical: 2, 
        backgroundColor: 'grey', 
        alignSelf: 'flex-start', 
        borderTopRightRadius: 10, 
        borderTopLeftRadius: 10
      },
      shadow: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.3,
        shadowRadius: 5
      }
  });