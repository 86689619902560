import * as React from 'react';
import { useState } from "react"
import { View, Text } from "react-native"
import { TextInput, Switch } from 'react-native-paper' 
import { Spacer } from '../layout';

export const ToggleField = ({ params, toolParams, setToolParams }) => {

    const onToggleSwitch = () => {
        setToolParams(Object.assign({}, toolParams, { [params.key]: !toolParams[params.key] }))
    }

    return <View style={{ flexDirection: 'row', alignItems: 'center', padding: 5 }}>
        <Text>{params.label}</Text>
        <Spacer width={20} />
        <Switch value={toolParams[params.key] ? toolParams[params.key] : false} onValueChange={onToggleSwitch} />
    </View>
}