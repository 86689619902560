import * as React from 'react';
import { View, Text } from "react-native"
import { DataTable } from "react-native-paper";


export const TableScreen = ({ output }) => {
  return <View>
    <DataTable>
      <DataTable.Header>
        {
          output?.header.map(head => {
            return <View key={head["index"] + "view"} style={{flex: 1, paddingBottom: 5}}>
              <Text>{head["name"]}</Text>
              <Text style={{color: 'dimgrey', fontSize: 14}}>{head["index"]} {head["type"]}</Text>
              </View>
          })
        }
      </DataTable.Header>

      {
        output?.data ? output.data.map((row, index) => {
          let keys = Object.keys(row).sort()
          return <DataTable.Row key={index + "Datarow"}>
            {
              keys.map((key, i) => {
                return <DataTable.Cell key={key + i}>{row[key]}</DataTable.Cell>
              })
            }
          </DataTable.Row>
        }) : <></>
      }
    </DataTable>
  </View>
}