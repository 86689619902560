import { StatusBar } from 'expo-status-bar';
import { Button, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useEffect, useState } from 'react';

import { HomeScreen } from './src/screens/Home';
import { SectionScreen } from './src/screens/SectionList';
import { EditorScreen } from './src/screens/Editor';
import { CombineSectionScreen } from './src/screens/CombineSections';
import LoadingScreen from './src/screens/LoadingScreen';
import AuthScreen from './src/screens/AuthScreen';

import { FinalizeScreen } from './src/screens/FinalizeRecipe';

import { io } from 'socket.io-client';
import { FontAwesome } from '@expo/vector-icons'
import * as RootNavigation from './RootNavigation';
import { TextInput } from 'react-native-paper';

const Stack = createNativeStackNavigator();

export default function AppMain() {
  const [firstLoad, setFirstLoad] = useState(true)
  const [code, setCode] = useState("")
  const rootnavRef = RootNavigation.navigationRef
  return (
    <NavigationContainer ref={rootnavRef}>
   <Stack.Navigator initialRouteName='Auth'>
   <Stack.Screen name="Auth">
          {props => <AuthScreen {...props}/>}
        </Stack.Screen>
   <Stack.Screen name="Loading">
          {props => <LoadingScreen {...props} firstLoad={firstLoad} setFirstLoad={setFirstLoad}/>}
        </Stack.Screen>
       

        <Stack.Screen options={{headerLeft: () => <></>}} name="Home" component={HomeScreen}/>
         
         <Stack.Screen name="Sections">
           {props => <SectionScreen {...props} />}
        </Stack.Screen>

        <Stack.Screen name="Editor">
           {props => <EditorScreen {...props} />}
        </Stack.Screen>

        <Stack.Screen name="Combine Sections">
           {props => <CombineSectionScreen {...props} />}
        </Stack.Screen>

        <Stack.Screen name="Finalize Recipe">
           {props => <FinalizeScreen {...props} />}
        </Stack.Screen>


      </Stack.Navigator>
      <StatusBar style="auto" />
    </NavigationContainer>
  );
}