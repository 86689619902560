import { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Button } from "react-native";
import { Feather } from '@expo/vector-icons'
import { DropdownList } from "../components/DropdownList";
import * as React from "react"
import { Spacer, PageTab} from "../components/layout";

import { InputField, ToggleField, KeywordOrRow, SelectDropdown } from "../components/Toolbox";
import { StringScreen } from "../components/EditorDisplays/StringScreen";
import { TableScreen } from "../components/EditorDisplays/TableScreen";
import { FilterBar } from "../components/EditorDisplays/FilterBar";
import { Socket } from "socket.io-client";
import { useSocket } from "../contexts/SocketContext";
import rm, { RecipeEditor } from "RecipeManager";
import { CLIOptions } from "RecipeManager/build/types/process_methods/CLI";
import { ComboOptions } from "RecipeManager/build/types/process_methods/Combination";
import { Page, Section } from "RecipeManager/build/types/main/main";
import { SNMPOptions } from "RecipeManager/build/types/process_methods/SNMP";
import ToolBoxItems from "RecipeManager/build/ToolboxItems";

interface DataDisplayRouterProps {
    output: any; // Define a specific type for 'output' if known
}

interface ToolbarParamsProps {
    tool: string;
    toolParams: any;
    setToolParams: (params: any) => void;
    ToolBox: any;
}

interface ToolBarWindowProps {
    socket: Socket;
    ToolBox: ToolBoxItems;
    selectedTool: string;
    toolParams: any;
    setToolParams: (params: any) => void;
    selectedPage: string;
    data: any[];
    selectTool: (tool: string) => void;
    device: any; // Define a specific type for 'device' if known
    section: any; // Define a specific type for 'section' if known
}

interface DataWindowProps {
    selectPage: (page: string) => void;
    selectedPage: string;
    selectTool: (tool: string) => void;
    section: Section; // Define a specific type for 'section' if known
    data: any[]; // Define a specific type for 'data' if known
    initData: string;
}

interface EditorScreenProps {
    route: {
        params: {
            device: any; // Define a specific type for 'device' if known
            section: any; // Define a specific type for 'section' if known
            socket: Socket;
        }
    };
    navigation: any; // Define a specific type for 'navigation' if known
}




const DataDisplayRouter: React.FC<DataDisplayRouterProps> = ({ output }) => {
  if (output?.type === 'table' && output.data) return <TableScreen output={output}/>
  else return <StringScreen output={output}/>
}

const ToolbarParams: React.FC<ToolbarParamsProps> = ({ tool, toolParams, setToolParams, ToolBox }) => {

  return <View>

    <Spacer height={10}/>
    {
      ToolBox[tool].params.map((inputObj: any, i: number) => {
        if (inputObj.input == "textInput") return <><InputField params={inputObj} toolParams={toolParams} setToolParams={setToolParams} key={inputObj.key +i}/><Spacer height={5}/></>
        if (inputObj.input == "toggle") return <><ToggleField params={inputObj} toolParams={toolParams} setToolParams={setToolParams} key={inputObj.key +i}/><Spacer height={5}/></>
        if (inputObj.input == "keywordOrRowNum") return <><KeywordOrRow params={inputObj} toolParams={toolParams} setToolParams={setToolParams} key={inputObj.key +i}/><Spacer height={5}/></>
        if (inputObj.input == "select") return <><SelectDropdown params={inputObj} toolParams={toolParams} setToolParams={setToolParams} key={inputObj.key +i}/><Spacer height={5}/></>
        return <></>
      })
    }
  </View>
}

const ToolBarWindow: React.FC<ToolBarWindowProps> = ({ socket, ToolBox, selectedTool, toolParams, setToolParams, selectedPage, data, selectTool, device, section }) => {
  return <View style={[{width: '100%', backgroundColor: 'white', padding: 10, borderRadius: 10}, styles.shadow]}>
        

  {
    //Param Section
  }
  <View style={{paddingTop: 35}}>
  {
    ToolBox[selectedTool]?.params ? <ToolbarParams ToolBox={ToolBox} tool={selectedTool} toolParams={toolParams} setToolParams={setToolParams}/> : <></>
  }
  </View>


<View style={{flexDirection: 'row', alignItems: 'center', position: 'absolute'}}>
  <Text style={{fontSize: 20, padding: 5}}>Toolbox</Text>
  {selectedPage == "init" ? <></> : <>
  <Spacer width={20}/>
  <DropdownList items={Object.keys(ToolBox).filter(item => {
    if (ToolBox[item]?.name == "none") return true;
    if (ToolBox[item]?.opType == "all") return true;
    return (ToolBox[item]?.opType == data.find((tab) => tab.name == selectedPage).outputDataFrame.type)
  })
    
    } selected={selectedTool} select={selectTool}/>
  <Spacer width={20}/>
  <Button title='add filter' onPress={() => {
    if (selectedTool !== "   -   ") {
    // Send tool + params to server
    socket.emit('addFilter', {did: device.id, sectionID: section.id, pageID: data.find(p => p.name == selectedPage).id, filter: {id: selectedTool, params: toolParams}})
    }

    setToolParams({})
    selectTool("   -   ")
    }}/>
    </>
  }
  </View>
</View>
}

const DataWindow: React.FC<DataWindowProps> = ({ selectPage, selectedPage, selectTool, section, data, initData }) => {
  return <View style={[{flex: 1, backgroundColor: 'white', borderRadius: 10, padding: 10}, styles.shadow]}>
  <Text>Command: {(section.method.opts as CLIOptions).command}</Text>
  <Spacer height={5}/>
  <View>
  <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
    <PageTab key={"rawtab"} label={"init"} selectPage={(newPage: any) => {
      selectPage(newPage);
      selectTool("   -   ");
    }} selectedPage={selectedPage}/>
  {
    (data as Page[]).map(page => {
      return <PageTab key={page.name} label={page.name} selectPage={(newpage: any) => {
        selectPage(newpage);
        selectTool("   -   ");
      }} selectedPage={selectedPage}/>
    })
  }
  </ScrollView>
  </View>
  <View style={{borderWidth: 1, flex: 1, padding: 10, borderBottomRightRadius: 10, borderBottomLeftRadius: 10, borderColor: 'blue'}}>
      <ScrollView>
          {
            (data as Page[]).find((tab) => tab.name == selectedPage)?.outputDataFrame.type ? ["string", "table"].includes((data as Page[]).find((tab) => tab.name == selectedPage)?.outputDataFrame.type!) ? <DataDisplayRouter output={
              (data as Page[]).find((tab) => tab.name == selectedPage)?.outputDataFrame
            } />: <></> : <View style={{ flex: 1 }}> 
            <Text>{initData}</Text>
          </View>
          }
        </ScrollView>
  </View>
  </View>
}

export const EditorScreen: React.FC<EditorScreenProps> = ({ route, navigation }) => {
  const socketContext = useSocket()
  const socket = socketContext.socket
  if (!socket) return <><Text>Socket Connection Error</Text></>

  const {device, section} = route.params
  const [data, setData] = useState<Page[]>(section.pages)
  const [selectedPage, selectPage] = useState(data[0].name)
  const [filters, setFilters] = useState(data[0].filters)
  const [ToolBox, setToolBox] = useState({})

  const [selectedTool, selectTool] = useState("   -   ")
  const [toolParams, setToolParams] = useState({})
  const [initData, setInitData] = useState(
    (section as Section ).method.type == "CLI" ?  ((section as rm.Section ).method.opts as CLIOptions).rawOutput : 
    //(section as Section ).method.type == "SNMP" ?  ((section as rm.Section ).method.opts as SNMPOptions) : 
    (section as Section ).method.type == "COMBO" ?  ((section as rm.Section ).method.opts as ComboOptions).rawOutput : ""
    )

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `${device.name}/${section.name} Editor`,
      headerRight: () => (
        <TouchableOpacity style={{borderWidth: 1, borderRadius: 3, borderColor: 'red', paddingVertical: 3, paddingHorizontal: 15, marginRight: 15 }} onPress={() => {
         
          socket.emit("deleteSection", {did: device.id, sectionID: section.id}, (response: any) => {
            if (response.status == "ok") {
              socket.emit("getSections", device.id)
              return navigation.navigate("Sections", {device: device, socket: socket})
            }
          });
          
          return 
          }}>
          <Text style={{color: 'red'}}>Delete Section</Text>
        </TouchableOpacity>
      )
    })

    socket.on('pageUpdate', (pageData) => {
      if (pageData) {
      setData(pageData)
      console.log("PAGEUPDATE", pageData, selectedPage)
      console.log("filters - ", pageData.find((page: any) => page.name == selectedPage)?.filters)
      setFilters(pageData.find((page: any) => page.name == selectedPage)?.filters || [])
      }
    })

    socket.on('toolBox', (toolBox) => {
      console.log("Got toolbox")
      console.log(toolBox)
      setToolBox(toolBox)
    })

    socket.emit('getToolBox')
  }, [])

  useEffect(() => {
    setFilters([])
    setFilters(data.find((pagedat: any) => pagedat.name == selectedPage)?.filters || [])
  }, [selectedPage])

  const removeLastFilter = () => {
    socket.emit('removeFilter', {did: device.id, sectionID: section.id, pageID: data.find(p => p.name == selectedPage)?.id})
  }

  return <View style={{flex: 1, flexDirection: 'row', padding: 10}}>
      <FilterBar data={data} selectedPage={selectedPage} removeLastFilter={removeLastFilter}/>
     
      <Spacer width={10}/>
      <View style={{flex: 8, flexDirection: 'column-reverse'}}>

      <DataWindow selectPage={selectPage} selectTool={selectTool} selectedPage={selectedPage} section={section} data={data} initData={initData}/>
<Spacer height={10}/>

<ToolBarWindow socket={socket} ToolBox={ToolBox} selectTool={selectTool} selectedTool={selectedTool} selectedPage={selectedPage} device={device} section={section} data={data} toolParams={toolParams} setToolParams={setToolParams}/>

</View>
  </View>
}


const styles = StyleSheet.create({
  container: { flex: 1, padding: 16, backgroundColor: '#fff', borderRadius: 10},
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
  selectedTab: {
    paddingHorizontal: 15, 
    paddingVertical: 2, 
    backgroundColor: 'blue', 
    alignSelf: 'flex-start', 
    borderTopRightRadius: 10, 
    borderTopLeftRadius: 10
    },
    deselectedTab: {
      paddingHorizontal: 15, 
      paddingVertical: 2, 
      backgroundColor: 'grey', 
      alignSelf: 'flex-start', 
      borderTopRightRadius: 10, 
      borderTopLeftRadius: 10
    },
    shadow: {
      shadowColor: 'black',
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.3,
      shadowRadius: 5
    }
});