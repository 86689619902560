import React, { useState } from 'react';
import { View, Text, Button } from 'react-native';
import { TextInput } from 'react-native-paper';
import { useSocket } from '../contexts/SocketContext';

// Define prop types
type AuthScreenProps = {
  navigation: {
    navigate: (screen: string, params?: any) => void;
    // You can provide other navigation function typings if you use them
  };
};

const AuthScreen: React.FC<AuthScreenProps> = ({ navigation }) => {
  // I noticed you initialized another state `authEntry` but never used it in your original component.
  // For the purpose of this conversion, I'll retain this state. Remove if not necessary.
  const [authEntry, setAuthEntry] = useState<string>('');
  const socketContext = useSocket()

  return (
    <View style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Text>Authenticate</Text>
      {/* Not sure if you intended to use `authEntry` here instead of `code`, I'll keep `code` for now. */}
      <TextInput 
        label="Code" 
        value={socketContext.code} 
        onChangeText={text => socketContext.setCode(text)} 
        mode='outlined'
        // Added `keyboardType` for better user experience. Adjust if not needed.
        keyboardType="number-pad" 
      />
      <Button title="Submit" onPress={() => {
        socketContext.connect(socketContext.code)
        navigation.navigate("Loading")
      }} />
    </View>
  );
}

export default AuthScreen;
