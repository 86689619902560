import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import AppMain from './AppMain';
import { SocketProvider } from './src/contexts/SocketContext';

export default function App() {
  return (
    <SocketProvider socketUrl='https://toolkit.telawatch.org'>
    <AppMain/>
    </SocketProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
