import React, { useEffect } from 'react';
import { View, Text } from 'react-native';
import * as RootNavigation from '../../RootNavigation'; // Assuming this is the correct path
import { useSocket } from '../contexts/SocketContext';

// Define prop types
type LoadingScreenProps = {
  navigation: any; // You can provide a more specific type if available
  firstLoad: boolean;
  setFirstLoad: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoadingScreen: React.FC<LoadingScreenProps> = ({ firstLoad, setFirstLoad }) => {
  const socketContext = useSocket()
  const socket = socketContext.socket

  useEffect(() => {
    
    const connect = () => {
      socketContext.connect(socketContext.code).then(() => {
     
      })
    };

    //if (!socketContext.socket?.id) connect();

    if (socketContext.socket?.id) {
        console.log("Connected ", socketContext.socket?.id);
        socket?.emit("setName", "Zach", () => {
          console.log("Name Set!");
          if (firstLoad) {
            console.log("navigating home");
            RootNavigation.navigate('Home', { socket });
            setFirstLoad(false);
          } else {
            console.log("navigating home");
            RootNavigation.navigate('Home', { socket });
            setFirstLoad(false);
          }
          return;
        });
      
    }

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Loading...</Text>
    </View>
  );
}

export default LoadingScreen;
