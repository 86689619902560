
import * as React from 'react';
import { useState } from "react"
import { View, Text } from "react-native"
import { TextInput, Switch } from 'react-native-paper' 
import { Spacer } from '../layout';
import { DropdownList} from '../DropdownList';

export const SelectDropdown = ({params, toolParams, setToolParams}) => {
    const [selected, select] = useState(toolParams[params.key] ? toolParams[params.key] : params.options[0])

    console.log(params, toolParams)
    React.useEffect(() => {
      setToolParams(Object.assign({}, toolParams, {[params.key]: params.options[0]}))
    }, [])

    return <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text>{params.label}</Text>
        <Spacer width={15}/>
      <DropdownList items={params.options} selected={selected} select={(item) => {
        select(item)
        setToolParams(Object.assign({}, toolParams, {[params.key]: item}))
      }}/>
    </View>
  }