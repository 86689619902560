import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, TextInput, StyleProp, ViewStyle } from "react-native";
import { Spacer, Table, Row, Cell } from "../components/layout";
import { TableHeader } from "../components/layout/Table";
import { Dropdown } from "../components/Dropdown";
import { Socket } from "socket.io-client";
import { useSocket } from "../contexts/SocketContext";

type HomeScreenProps = {
  navigation: {
    navigate: (screen: string, params: any) => void;
  };
};

type TableHeadItem = {
  label: string;
  flex: number;
  col: string;
};

type Device = {
  _id: string;
  name: string;
  created: Date;
  updated: Date;
};

export const HomeScreen: React.FC<HomeScreenProps> = ({ navigation }) => {
    const socketContext = useSocket()
    const socket = socketContext.socket

if (!socket) {
  // Handle the absence of socket, perhaps render an error message or redirect to another screen.
  return <View><Text>Error: Socket not available.</Text></View>;
}
  const [tableHead, setTableHead] = useState<TableHeadItem[]>([
    { label: "Name", flex: 2, col: "c1" },
    { label: "Created", flex: 1, col: "c2" },
    { label: "Updated", flex: 1, col: "c3" },
  ]);
  const [tableData, setTableData] = useState<Device[]>([]);
  const [deviceDropdownOpen, openDeviceDropdown] = useState<boolean>(false);
  const [newDeviceName, setNewDeviceName] = useState<string>("");

  useEffect(() => {
    socket.on("deviceHead", (devices: Device[]) => {
      setTableData(devices);
      console.log("Devices: ", devices);
    });

    socket.emit("devices", "Zach", (devices: Device[]) => {
      console.log("devices: ", devices);
    });
  }, []);

  const addDevice = () => {
    console.log("Add device")
    socket.emit("newDevice", { name: newDeviceName }, "Zach");
  };

  return (
    <View style={{ padding: 20 }}>
      <Spacer height={40} />
      <Table>
        <TableHeader items={tableHead} itemOnPress={(x: any) => console.log(x)} />
        {tableData.map((device) => (
          <Row
            key={device._id}
            onPress={() => {
              navigation.navigate("Sections", { device, socket });
            }}
          >
            <Cell col={"c1"} val={device.name} flex={tableHead[0].flex} disabled />
           {
            /*
           } <Cell
              col={"c2"}
              val={new Date(device.created).toISOString()}
              flex={tableHead[1].flex}
              disabled
            />
            <Cell
              col={"c3"}
              val={new Date(device.updated).toISOString()}
              flex={tableHead[2].flex}
              disabled
            />
            */
          }
          </Row>
        ))}
      </Table>
      <View style={{ flexDirection: "row", alignItems: "center", position: "absolute" }}>
        <Text style={{ fontSize: 25 }}>Devices</Text>
        <Spacer width={10} />
        <Dropdown opened={deviceDropdownOpen} open={openDeviceDropdown}>
          <View
            style={{
              borderWidth: 1,
              position: "absolute",
              zIndex: 2,
              backgroundColor: "white",
              top: 25,
              left: 0,
              padding: 10,
              borderRadius: 5,
              maxWidth: 500,
            }}
          >
            <Text style={{ flex: 1 }}>Add Device</Text>
            <Text>Name</Text>
            <TextInput onChangeText={setNewDeviceName} value={newDeviceName} />
            <View style={{ flexDirection: "row", flex: 1, paddingVertical: 10 }}>
              <TouchableOpacity
                onPress={() => {
                  addDevice();
                  openDeviceDropdown(false);
                }}
                style={{ flex: 1, alignItems: "center" }}
              >
                <Text>Add Device</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ flex: 1, alignItems: "center" }}
                onPress={() => openDeviceDropdown(false)}
              >
                <Text>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Dropdown>
      </View>
    </View>
  );
};
