import * as React from 'react';
import { useState } from "react"
import { View, Text } from "react-native"
import { TextInput, Switch } from 'react-native-paper' 
import { Spacer } from '../layout';

export const InputField = ({params, toolParams, setToolParams}) => {
    const [text, setText] = useState(toolParams[params.key] ? toolParams[params.key] : "")
    return <TextInput disabled={params.tieDisabled ? !toolParams[params.tieDisabled] : false} label={params.label} value={text} onChangeText={(txt) => {
      return setText(txt)
    }} onBlur={(txt) => {setToolParams(Object.assign({}, toolParams, {[params.key]: text}))}}/>
  }